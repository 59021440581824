import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import {leadershipBase64} from '../../assets/pagehero-base64/leadership-base64';
import '../../styles/typography.scss';

import Leader from '../../components/shared-components/leader';

// import {stepan} from '../../assets/leadership/leads/Stepan';
// import {armen} from '../../assets/leadership/leads/Armen';
// import {nika} from '../../assets/leadership/leads/Nika';
// import {ani} from '../../assets/leadership/leads/Ani';
// import {narek} from '../../assets/leadership/leads/Narek';
// import {vahe} from '../../assets/leadership/leads/Vahe';
// import {chris} from '../../assets/leadership/leads/Chris';
// import {lusine} from '../../assets/leadership/leads/Lusine';
// import {hayk} from '../../assets/leadership/leads/Hayk';

// import {peopleFirst} from '../../assets/leadership/leads/people-first';

import atob from 'atob';

import {Link, navigate} from 'gatsby';
import {Col, Row} from 'react-bootstrap';
import '../../styles/typography.scss';
import './styles.scss';
const Leadership = () => {

  useEffect(() => {
    navigate('/')
  }, []);
  
  const leads = [
    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/stepan.png',
      fullName: 'Stepan Aslanyan',
      position: 'CEO',
      hrefLinkedin: 'https://www.linkedin.com/in/saslanyan/',
    },
    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/armen.png',
      fullName: 'Armen A. Avakian',
      position: 'CBDO',
      hrefLinkedin: 'https://www.linkedin.com/in/armenavakavakian/',
    },
    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/chris.png',
      fullName: 'Chris Closset',
      position: 'CMO',
      hrefLinkedin: 'https://www.linkedin.com/in/chrisclosset/',
    },
    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/vahe.png',
      fullName: 'Vahe Sargsyan',
      position: 'CTO',
      hrefLinkedin: 'https://www.linkedin.com/in/vahesa/',
    },
    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/nika.png',
      fullName: 'Veronika Jaghinyan',
      position: 'Front End Team Lead',
      hrefLinkedin: 'https://www.linkedin.com/in/veronika-jaghinyan-3743609b/',
    },
    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/hayk.png',
      fullName: 'Hayk Ghukasyan',
      position: 'Back End Team Lead',
      hrefLinkedin: ' https://www.linkedin.com/in/hayk-ghukasyan-7a384341/',
    },
    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/narek.png',
      fullName: 'Narek Amirkhanyan',
      position: 'COO',
      hrefLinkedin: 'https://www.linkedin.com/in/narekamirkhanyan/',
    },

    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/ani.png',
      fullName: 'Ani Yeritsyan',
      position: 'HRM',
      hrefLinkedin: 'https://www.linkedin.com/in/ani-yeritsyan/',
    },

    {
      img: 'https://storage.googleapis.com/hexact/leadership/png/lusine.png',
      fullName: 'Lusine Danielyan',
      position: 'PM',
      hrefLinkedin: 'https://www.linkedin.com/in/lusine-danielyan-a2aa2713a/',
    },
  ];

  return (
    <Layout
      pageheroColor="#00C869"
      pageheroText="Leading by example today to build tomorrow’s leaders at Hexact."
      pageheroTitle="Leadership"
      pageheroImg={atob(leadershipBase64)}
      className="leadership-page"
    >
      <div className="leadership-container">
        <div className="leadership-header">
          <div className="badge-title">LEADERSHIP</div>
          <h3 className="page-title">Hexact Task Force</h3>
          <p className="secondary-text">
            We stand on the shoulders of AI giants who made our journey possible and collaborate with each other to
            leave a significant impact on the lives of our stakeholders.
          </p>
        </div>

        <div className="leaders-section">
          <div className="row">
            {leads.map(item => (
              <div className="col-sm-12 col-md-6 col-lg-4 p-4">
                <Leader
                  img={item.img}
                  fullName={item.fullName}
                  position={item.position}
                  hrefLinkedin={item.hrefLinkedin}
                />
              </div>
            ))}
          </div>
        </div>

        {/* <div className="leadership-footer">
          <div className="people-first">
            <Row className="people-first-wrapper">
              <Col lg={7} className="d-none d-lg-block">
                <img src='https://storage.googleapis.com/hexact/leadership/png/people-first.png' alt="team" />
              </Col>
              <Col lg={5} className="m-auto content-container">
                <h2>People First</h2>
                <img src='https://storage.googleapis.com/hexact/leadership/png/people-first.png' alt="team" className="d-lg-none" />
                <p>
                  People at Hexact are the most valuable asset in the company. It's not just their efforts but the level
                  of enthusiasm they show as they grow our movement. They believe in what Hexact represents, and we
                  similarly believe in them.
                </p>
                <Link to="/careers" className="button-general">
                  Join our team
                </Link>
              </Col>
            </Row>
          </div>
        </div> */}
      </div>
    </Layout>
  );
};

export default Leadership;
